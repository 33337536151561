<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-18 08:08:58
 * @ Description: Fixer's 404 page.
 -->

<template>
    <div
        style="background-color: #F9FBFD; height: 100vh; display: flex; align-items: center;"
    >
        <v-row
            align="center"
        >
            <v-col
                cols="12"
                class="text-center rob-60-m py-0"
                style="color: rgba(136, 138, 141);"
            >
                404
            </v-col>
            <v-col
                cols="12"
                class="text-center"
            >
                <v-img
                    src="/general/Fixer404.png"
                    height="100"
                    width="100"
                    contain
                    class="mx-auto"
                />
            </v-col>
            <v-col
                cols="12"
                class="text-center rob-20-m pt-0"
                style="color: rgba(136, 138, 141);"
            >
                Oops! Page Not Found
            </v-col>
            <v-col
                cols="12"
                class="text-center"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    @click="$router.push($store.state.storeRouter.currentRoute)"
                >
                    Go Back
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    export default {
        name: 'GeneralNotFoundPage'
    }
</script>
<style scoped>
    @import url('../../assets/style/style.fonts.css');
</style>
